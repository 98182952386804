<template>
    <modal ref="modalDondeTeCuentras" titulo="¿En donde te encuentras?" tamano="modal-md" no-aceptar no-adicional no-cancelar>
        <div class="row mx-0 j-center mb-5 mt-3">
            <div class="col-8">
                <div class="row mx-0 justify-content-center">
                    <div v-for="pais in paises" :key="pais.id_pais" class="card-country cr-pointer" @click="seleccionarPais(pais.id_pais)">
                        <div class="row mx-0 justify-content-center">
                            <div :class="`border-card${paisSeleccionado == pais.id_pais ? '-active' : ''} br-10 p-1`">
                                <img :src="pais.bandera" width="70" height="50" class="br-10" />
                            </div>
                        </div>
                        <p class="text-center f-16 f-500 mt-2">
                            {{ pais.pais }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 j-center mb-3 mt-3">
            <div class="col-8">
                <el-select v-model="ciudad" placeholder="Seleccionar ciudad" class="w-100" @change="seleccionarCiudad">
                    <el-option
                    v-for="item in ciudades"
                    :key="item.id"
                    :label="item.ciudad"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '~/services/invitado/invitado'

export default {
    data(){
        return {
            loading: false,
            paises: [],
            ciudadesPais: [],
            ciudades: [],
            paisSeleccionado: '',
            ciudad: '',
            selectCiudad:false
        }
    },
    computed: {
        ...mapGetters({
            tienda: 'invitado/tienda',
            token: 'auth/obtenerToken'
        }),
    },
    methods: {
        async getData(){
            try {
                const { data } = await Service.getData();

                if(data.redirect_home && !this.selectCiudad){
                    await this.setTiendaDefault(data.paises[0].id_ciudad)
                    this.redirectView()
                    return
                }


                this.paises = data.paises;
                this.ciudadesPais = data.ciudades;
                if(!_.isEmpty(this.tienda)){
                    this.tiendaSeleccionada();
                }
                this.openModal()
            } catch(e){
                console.error(e);
            }
        },
        seleccionarPais(idPais){
            this.paisSeleccionado = idPais;
            this.ciudad = '';
            this.ciudades = [];
            let ciudadesPais = this.ciudadesPais.find((element) => element.id_pais === idPais);
            this.ciudades = ciudadesPais.ciudades;
        },
        async setTiendaDefault(idCiudad = null){

            const { data } = await Service.getTienda(idCiudad);
            this.eliminarStorageInvitado();
            const asignar = JSON.stringify(data.tienda);
            localStorage.setItem('tienda', asignar);
            this.$store.commit('invitado/obtenerTienda', data.tienda);
        },
        redirectView(){
            if(this.token){
                return this.$router.push({ name: 'home' });
            }
            this.$router.push({ name: 'invitado' });
        },
        async seleccionarCiudad(){
            try {
                if(!this.ciudad)return;
                await this.setTiendaDefault(this.ciudad)
                this.openModal()
                this.redirectView()
            } catch(e){
                console.error(e);
            } 
        },
        async tiendaSeleccionada(){
            this.paisSeleccionado = this.tienda.id_pais;
            let ciudadesPais = this.ciudadesPais.find((element) => element.id_pais === this.paisSeleccionado);
            this.ciudades = ciudadesPais.ciudades;
            this.ciudad = this.tienda.id_ciudad_Seleccionada;
        },
        async eliminarStorageInvitado(){
            localStorage.removeItem('tienda');
            this.$store.commit('invitado/obtenerTienda', {});

            localStorage.removeItem('carritoInvitado');
            this.$store.commit('invitado/obtenerCarrito', []);

            localStorage.removeItem('favoritosInvitado');
            this.$store.commit('invitado/obtenerProductosFavoritos', []);
        },
        toggle(bandera){
            this.selectCiudad = false
            if(bandera == "asignar"){
                this.eliminarStorageInvitado();
            }

            if(bandera === 'actualizar'){
                this.selectCiudad = true
            }

            this.getData();
        },
        openModal(){
            this.$refs.modalDondeTeCuentras.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.card-country{
    width: 110px;
    .border-card{
        border: 2px solid transparent;
        &-active{
            display: block;
            border: 2px solid var(--color-general);
        }
    }
}
</style>